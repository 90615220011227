import { computed, Injectable, signal } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { toPromise } from '@dougs/core/utils';
import { DrawerService } from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { TaskService } from '../../task.service';

@Injectable()
export class TaskItemComponentService {
  private readonly isLoading = signal(false);
  isLoading$ = computed(() => this.isLoading());

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly taskService: TaskService,
    private readonly drawerService: DrawerService,
  ) {}

  async toggleCompleted(task: Task): Promise<void> {
    if (!this.isLoading()) {
      if (task.completedAt) {
        await this.taskService.unCompleteTask(task, true);
      } else if (!task.metadata?.workflowBypass?.firstErrorAt) {
        await this.taskService.completeTask(task, true);
      }
    }
  }

  async completeTask(task: Task, bypass = false): Promise<void> {
    if (!this.isLoading()) {
      this.isLoading.set(true);
      await this.taskService.completeTask(task, true, bypass);
      this.isLoading.set(false);
    }
  }

  async onUploadFiles(task: Task, files: FileList, type: string): Promise<void> {
    const { TaskFilesValidationComponentDrawer } = await import(
      '../../../modals/task-files-validation/task-files-validation-drawer.component'
    );
    const filesToUpload: File[] | null | undefined = (
      await toPromise(
        this.drawerService.open<File[] | null>(TaskFilesValidationComponentDrawer, {
          data: {
            task,
            files,
          },
        }).afterClosed$,
      )
    )?.data;
    if (filesToUpload) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(
        task,
        filesToUpload,
        {
          fileType: type,
        },
        true,
      );
    }
  }

  async onDeleteFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment, true);
  }

  getButtonText(task: Task, taskTemplate: TaskTemplate): string {
    if (!task || !taskTemplate) {
      return '';
    }

    const subTasks: TaskTemplate[] | undefined = taskTemplate.tasks || Object.values(taskTemplate.taskLibrary!);
    return subTasks?.find((subTaskTemplate) => subTaskTemplate.code === task.code)?.config?.bypassableText ?? '';
  }
}
