import { Pipe, PipeTransform } from '@angular/core';
import { Task, TASK_LEGAL_STATE } from '@dougs/task/dto';

@Pipe({
  name: 'taskHeaderClass',
  standalone: true,
})
export class TaskHeaderClassPipe implements PipeTransform {
  transform(task: Task, isLegalCollaborator = false): string {
    if (isLegalCollaborator) {
      switch (task.legalState) {
        case TASK_LEGAL_STATE.TO_PRODUCE:
          return 'task-card__header--alert';
        case TASK_LEGAL_STATE.TO_REGISTER:
        case TASK_LEGAL_STATE.WAITING_TAX_REGISTRATION:
          return 'task-card__header--yellow';
        case TASK_LEGAL_STATE.WAITING_CLIENT_MEETING:
        case TASK_LEGAL_STATE.WAITING_CLIENT_SIGNATURE:
          return 'task-card__header--primary';
        case TASK_LEGAL_STATE.WAITING_CLIENT_BENEFITS:
        case TASK_LEGAL_STATE.WAITING_COMPANY_REGISTRATION:
          return 'task-card__header--purple';
        case TASK_LEGAL_STATE.WAITING_PROJECT_VALIDATION:
        case TASK_LEGAL_STATE.WAITING_BALANCE_SHEET:
          return 'task-card__header--green';
        case TASK_LEGAL_STATE.PENDING_QUOTE:
        case TASK_LEGAL_STATE.WAITING_FOR_INFORMATION:
          return 'task-card__header--light-yellow';
        case TASK_LEGAL_STATE.WAITING_CLIENT_CAPITAL_DEPOSIT:
          return 'task-card__header--pink';
        default:
          return 'task-card__header--neutral';
      }
    }
    return task.overdue ? 'task-card__header--alert' : '';
  }
}
